
<i18n locale="th" lang="yaml" >
ticket_action.label : "สถานะของ Ticket"

ticket_action.duplicate : "Clone :: สร้าง Ticket ใหม่แบบเดียวกัน"

ticket_action.investigate : "Investigate : ตรวจสอบ"
ticket_action.investigate.message : "ยืนยันเปลี่ยนสถานะ Ticket เป็นเริ่มการตรวจสอบ ?"

ticket_action.reopen : "Reopen : นำ Ticket กลับมา"
ticket_action.reopen.message : "เปิด Ticket นี้กลับมาใหม่ โดยอาจจะเกิดจากปัญหาต่าง ๆ"
ticket_action.reopen.reason.appeal : "ขอทบทวนปัญหา"
ticket_action.reopen.reason.missing_analyze : "วิเคราะห์ผิด"
ticket_action.reopen.reason.found_again : "ยังเจอปัญหาอยู่"
ticket_action.reopen.reason.mistake : "ปัญหายังไม่ได้แก้ไข"

ticket_action.pending : "Pending : รอการแก้ไข"
ticket_action.pending.message : "Ticket นี้ไม่สามารถแก้ไขปัญหาได้ในปัจจุบัน โดยอาจจะต้องรอให้มีการปรับหรือแก้ไขบางส่วนอยู่"
ticket_action.pending.reason.wait_evidence : "รอเจอ หรือ มีข้อมูลมากกว่านี้"
ticket_action.pending.reason.wait_software : "รอแก้ Bug"
ticket_action.pending.reason.wait_supplier : "รอชิ้นส่วนหรือการแก้ไขอะไหล่"

ticket_action.close : "Close : ปิด Ticket"
ticket_action.close.message : "ทำการปิด Ticket อาจจะเนื่องมาจากแก้ไขปัญหาเรียบร้อย หรือ ต้องนำเครื่องเข้ามาเพื่อปรับการทำงาน"

ticket_action.cancel : "Cancel : ยกเลิก Ticket"
ticket_action.cancel.message : "ยกเลิก Ticket เนื่องจากข้อมูลผิดพลาด หรือ อื่น ๆ"
ticket_action.cancel.reason.duplicate : "เปิด Ticket ซ้ำ"
ticket_action.cancel.reason.not_found : "ไม่พบปัญหาจริง ๆ"
ticket_action.cancel.reason.error : "ข้อมูลผิดพลาด"
ticket_action.cancel.reason.checkup.finish : "ตรวจสอบเรียบร้อยแล้ว"
ticket_action.cancel.reason.checkup.cancel_before_service : "ลูกค้ายกเลิกก่อนเปิดใบซ่อม"
ticket_action.cancel.reason.checkup.cancel_after_service : "ลูกค้ายกเลิกหลังเปิดใบซ่อม"
ticket_action.cancel.reason.checkup.no_show : "ลูกค้าไม่มา"

ticket_action.field.reason : "เหตุผลเพิ่มเติม *"

ticket_action.success : "ปรับสถานะของ Ticket {ticket} เรียบร้อย"
ticket_action.success.no_changed : "สถานะของ Ticket {ticket} ไม่ได้มีการเปลี่ยนแปลง"

</i18n>

<template>
	<div class="ticket-action-pane">
		<a-dropdown v-if="ticket.id > 0 && canUpdateStatus">
			<a-button> {{$t('ticket_action.label')}} <a-icon type="down" /> </a-button>
			<a-menu slot="overlay" class="ticket-action-menu" @click="handleMenuSelect">
				<template v-for="menu in menuItems">
					<a-menu-divider v-if="menu.divider && menu.visible" :key="menu.key"/>
					<a-menu-item v-if="!menu.divider && menu.visible" :key="menu.key" :disabled="menu.disabled">
						<component :is="menu.icon"  :class="menu.iconCss + ' icon'"/> {{menu.title}}
					</a-menu-item>
				</template>
			</a-menu>
		</a-dropdown>
		<MyConfirmReasonModel
			ref="reasonModalRef"
			:title="cModalTitle"
			:message="cModalMessage"
			:reasons="cReasons"
			:reason-label="$t('ticket_action.field.reason')"
			:confirm-again="true"
			@cancel="handleModalCancel"
			@confirm="handleModalConfirm">
			<component :is="selectedMenu.icon" slot="icon" :class="selectedMenu.iconCss" />
		</MyConfirmReasonModel>
	</div>
</template>

<script>
import {Menu,Dropdown} from "ant-design-vue"
import MyConfirmReasonModel from "@components/input/MyConfirmReasonModal.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import PageMixin from "@mixins/PageMixin.vue"

import InvestigateIcon from "vue-material-design-icons/CrosshairsGps.vue"
import CloseIcon from "vue-material-design-icons/ClipboardCheckOutline.vue"
import PendingIcon from "vue-material-design-icons/PauseCircleOutline.vue"
import CancelIcon from "vue-material-design-icons/Cancel.vue"

import TicketReopenIcon from "vue-material-design-icons/SyncAlert.vue"
import CopyIcon from "vue-material-design-icons/ContentCopy.vue"
import 'vue-material-design-icons/styles.css';
import LoginInfoMixin from '@/src/mixins/LoginInfoMixin.vue'
import {technician} from "@utils/objectUtil"


export default {
	components : {
		"a-menu" : Menu, "a-menu-item" : Menu.Item,
		"a-menu-divider" : Menu.Divider ,
		"a-dropdown" : Dropdown ,
		CopyIcon , MyConfirmReasonModel,
	} ,
	mixins : [PageMixin, LoginInfoMixin] ,
	props : {
		ticket : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			selectedMenu : {},
		}
	} ,
	computed : {
		canUpdateStatus() {
			return this.currentUser.user_role !== technician
		} ,
		canInvestigate() {
			return ['new','reopen'].includes(this.ticket.status);
		} ,
		canPending() {
			return ['new','investigate','reopen'].includes(this.ticket.status);
		} ,
		canClose() {
			return this.ticket.status == 'resolved'
		} ,
		canReopen() {
			return ['resolved','close'].includes(this.ticket.status);
		} ,
		canCancel() {
			return this.ticket.status != 'cancel'
		} ,
		menuItems() {
			const menus = [
				{
					key : "investigate" ,
					title : this.$t('ticket_action.investigate') ,
					visible : this.canInvestigate ,
					disabled : this.ticket.status == 'investigate' ,
					icon : InvestigateIcon,
				} ,
				{
					key : "reopen" ,
					title : this.$t('ticket_action.reopen') ,
					visible : this.canReopen,
					icon : TicketReopenIcon,
					modal : {
						reasons : [
							this.$t('ticket_action.reopen.reason.appeal') ,
							this.$t('ticket_action.reopen.reason.missing_analyze') ,
							this.$t('ticket_action.reopen.reason.found_again') ,
							this.$t('ticket_action.reopen.reason.mistake') ,
						]
					}
				} ,
				{
					key : "close" ,
					title : this.$t('ticket_action.close') ,
					visible : this.canClose,
					icon : CloseIcon ,
					iconCss : "text-success" ,
				} ,
			]
			if (menus.length > 0 && (this.canPending || this.canClose)) {
				menus.push([
					{
						key : "divider1" ,
						divider : true,
						visible : this.canPending || this.canClose,
					} ,
				])
			}
			menus.push({
					key : "pending" ,
					title : this.$t('ticket_action.pending') ,
					visible : this.canPending,
					icon : PendingIcon,
					iconCss : "text-warning" ,
					modal : {
						reasons : [
							this.$t('ticket_action.pending.reason.wait_evidence') ,
							this.$t('ticket_action.pending.reason.wait_software') ,
							this.$t('ticket_action.pending.reason.wait_supplier') ,
						]
					}
				})
			menus.push({
					key : "cancel" ,
					title : this.$t('ticket_action.cancel') ,
					visible : this.canCancel,
					icon : CancelIcon ,
					iconCss : "text-error" ,
					modal : {
						reasons : this.ticket.search_tags.includes('checkup') ? [
							this.$t('ticket_action.cancel.reason.checkup.no_show') ,
							this.$t('ticket_action.cancel.reason.checkup.finish') ,
							this.$t('ticket_action.cancel.reason.checkup.cancel_before_service') ,
							this.$t('ticket_action.cancel.reason.checkup.cancel_after_service') ,
						] : [
							this.$t('ticket_action.cancel.reason.duplicate') ,
							this.$t('ticket_action.cancel.reason.not_found') ,
							this.$t('ticket_action.cancel.reason.error') ,
						]
					}
				})
			return menus
		} ,
		cModalTitle() {
			if (this.selectedMenu) {
				return this.selectedMenu.title
			} else {
				return ''
			}
		} ,
		cModalMessage() {
			if (this.selectedMenu) {
				return this.$t('ticket_action.'+this.selectedMenu.key+'.message')
			} else {
				return ''
			}
		} ,
		cReasons() {
			if (this.selectedMenu.modal) {
				return this.selectedMenu.modal.reasons
			} else {
				return []
			}
		} ,

	} ,
	methods : {
		handleMenuSelect({key}) {
			const menuItem = this.menuItems.find((menu) => menu.key == key)
			this.selectedMenu = menuItem
			if (menuItem && menuItem.visible && menuItem.modal) {
					this.$nextTick(() => {
						this.$refs.reasonModalRef.show()
					})
			} else {
				// Just confirm
				this.$confirm({
					title : this.cModalTitle,
					content : this.cModalMessage ,
					okText : this.$t('common.confirm') ,
					maskClosable : true,
					onOk: ()=> {
						this.callUpdateStatus(undefined,false)
					} ,
					onCancel : () => {
						this.selectedMenu = {}
					}
				});
			}
		} ,
		handleModalConfirm(payload) {
			this.callUpdateStatus(payload.reason,true)
		} ,
		handleModalCancel() {
			this.selectedMenu = {}
		} ,
		callUpdateStatus(reason,fromModal) {
			let newStatus = this.selectedMenu.key
			if ('status' in this.selectedMenu) {
				newStatus = this.selectedMenu.status
			}

			this.showPageLoading();
			const formData = {
				status : newStatus, 'status_reason' : reason
			}
			axios.post('/api/tickets/'+this.ticket.id+'/update-status',formData).then((response)=>{
				const rtnData = response.data.data;
				if (!rtnData.changed) {
					this.$message.success(this.$t('ticket_action.success.no_changed',{"ticket" : this.ticket.ticket_no}))
				} else {
					this.$emit('comment',rtnData);
					this.$emit('ticket',rtnData.ticket);

					this.$message.success(this.$t('ticket_action.success',{"ticket" : this.ticket.ticket_no}))
				}
				if (fromModal && this.$refs.reasonModalRef)
					this.$refs.reasonModalRef.hide()
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=> {
				this.hidePageLoading();
			})
		}
	}
}
</script>
<style lang="less" scoped>
.ticket-action-pane {
	display : inline-block;
}

</style>
<style lang="less">
@ticket-menu-icon-size : 1.35em;
.ticket-action-menu {
	.icon {
		.material-icon-size(@ticket-menu-icon-size);
		margin-right : 6px;
	}
}
</style>

<i18n locale="th" lang="yaml" >
note.add.label : "เพิ่ม Note"
note.modal.title : "Drone Note Editor"

note.pin : "ปักหมุด"

note.add.success : "ทำการเพิ่ม Note ใหม่เรียบร้อย"
note.update.success : "ทำการแก้ไข Note ใหม่เรียบร้อย"
note.delete.title : "ยืนยันการลบ Note"
note.delete.message : "คุณต้องการลบ Note นี้ ?"
note.delete.success : "ทำการลบ Note เรียบร้อย"
</i18n>

<template>
	<div class="drone-notes">
		<a-list
			:loading="loading"
			item-layout="horizontal"
			class="note-list mycard-ant-list"
			row-key="id"
			:data-source="noteDataList">
			<template v-if="showLoadingMore" v-slot:loadMore>
				<div class="load-more-pane">
					<a-spin v-if="loadingMore" />
					<a-button v-else type="link" @click="handleLoadMore">
						{{$t('common.load_more')}}
					</a-button>
				</div>
			</template>
			<template v-slot:renderItem="noteData">
				<a-list-item>
					<DroneNoteCard
						:note-data="noteData"
						@update="handleNoteUpdate"
						@delete="handleNoteDelete"/>
				</a-list-item>
			</template>
		</a-list>
		<div v-if="canAdd" class="note-action-pane">
			<a-button type="primary" @click="handleAdd">
				{{$t('note.add.label')}}
			</a-button>
		</div>

		<a-modal
			v-if="canAdd || canUpdate"
			:visible="modalVisible"
			:title="$t('note.modal.title')"
			:ok-text="$t('common.save')"

			:ok-button-props="{ props: { disabled: modalOkDisable , loading: modalLoading } }"
			:cancel-button-props="{ props: { disabled: modalLoading } }"
			:mask-closable="false"
			@ok="handleModalOk"
			@cancel="handleModalCancel">
			<RichTextInput
				v-model="noteContentRich"
				@changeText="handleNoteChange"/>
			<div class="note-pin-form">
				<a-switch v-model="notePin" size="small" />
				<label>{{$t('note.pin')}}</label>
			</div>
		</a-modal>
	</div>
</template>

<script>
import {List,Switch} from "ant-design-vue"
import RichTextInput from "@components/input/RichTextInput.vue"
import DroneNoteCard from "@components/drone/DroneNoteCard.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import {fulfillDroneNote} from "@utils/objectUtil"
import {isStringEmpty as _isStringEmpty} from "@utils/stringUtil"
export default {
	components : {
		RichTextInput,DroneNoteCard,
		"a-list" : List, "a-list-item" : List.Item,
		"a-switch" : Switch,
	} ,
	props : {
		canAdd : {
			type : Boolean,
			default : false,
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		drone : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			loading : false ,
			noteDataList : [] ,
			loadingMore : false,
			pagination : {
				pageSize : 5,
				current : 0,
				total : 0,
			} ,
			modalVisible : false,
			modalLoading : false,
			modalOkDisable : false,

			selectedNote : {} ,
			noteContentRich : '',
			notePin : false,
		}
	} ,
	computed: {
		showLoadingMore() {
			return this.noteDataList.length < this.pagination.total
		}
	} ,
	watch: {
		'drone.id' : {
			handler() {
				this.newLoad()
			}
		}
	} ,
	mounted() {
		this.newLoad()
	} ,
	methods : {
		newLoad() {
			this.loading = true
			this.pagination.total = 0
			this.pagination.current = 0
			this.noteDataList = []
			this.fetchData()
		} ,
		fetchData(){
			if (!this.drone.id)
				return
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : this.pagination.current + 1,
			}

			this.loadingMore = true;
			axios.get("/api/drones/notes/"+this.drone.id,{params: {search}}).then((response)=>{
				const page = response.data.data.pagination;
				const dataBucket = response.data.data.bucket;

				response.data.data.notes.forEach((note)=> {
					const noteData = fulfillDroneNote(note,dataBucket);
					if (noteData.id)
						this.noteDataList.push(noteData);
				})

				this.pagination.current++;
				this.pagination.total = page.total
			}).catch((error)=>{
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
				this.loadingMore = false
			})
		} ,
		handleLoadMore() {
			if (this.showLoadingMore)
				this.fetchData()
		} ,
		handleAdd() {
			this.modalVisible = true
			this.modalOkDisable = true
		} ,
		handleModalOk() {
			this.modalLoading = true
			let url = "/api/drones/add-note/"+this.drone.id
			if (this.selectedNote.id) {
				// update
				url = "/api/drones/update-note/"+this.selectedNote.id
			}

			axios.post(url,{detail_rich : this.noteContentRich,pin : this.notePin}).then((response) => {
				const newNoteData = fulfillDroneNote(response.data.data.note,response.data.data.bucket)
				if (newNoteData.id) {
					if (this.selectedNote.id)	 {
						this.$message.success(this.$t('note.update.success'))
					} else {
						this.pagination.total++
						this.$message.success(this.$t('note.add.success'))
					}
					this.newLoad()
				}

				this.handleModalCancel()
			}).catch((error)=> {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.modalLoading = false
			})
		} ,
		handleModalCancel() {
			this.modalVisible = false
			this.modalLoading = false
			this.selectedNote = {}
			this.noteContentRich = ''
			this.notePin = false
		} ,
		handleNoteChange(text) {
			this.modalOkDisable = _isStringEmpty(text)
		} ,
		handleNoteUpdate(noteData) {
			this.modalVisible = true
			this.modalOkDisable = false
			this.selectedNote = noteData
			this.noteContentRich = noteData.note.detail_rich
			this.notePin = (noteData.note.pin > 0)
		} ,
		handleNoteDelete(noteData) {
			this.$confirm({
				title : this.$t('note.delete.title') ,
				content : this.$t('note.delete.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.loading = true
					axios.delete("/api/drones/delete-note/"+noteData.id).then((response) => {
						const index = this.noteDataList.findIndex((note)=>note.id==noteData.id);
						if (index >= 0) {
							this.noteDataList.splice(index,1)
							this.pagination.total--
						}

						this.$message.success(this.$t('note.delete.success'));
					}).catch((error)=> {
						this.$message.error(ApiError.getDisplayErrorMessage(this,error))
					}).finally(()=>{
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.note-action-pane {
	text-align : right;
	margin-top : 8px;
}
.note-list::v-deep {

	&.mycard-ant-list.ant-list ,
	&.mycard-ant-list.ant-list > .ant-spin-nested-loading {
		min-height: 95px;
	}
	li.ant-list-item:nth-child(odd){
		background-color : @gray-2;
	}
	li.ant-list-item {
		border-bottom : none;
	}
}
.note-pin-form {
	margin-top : 8px;
	label {
		color : @info-color;
	}
}
</style>

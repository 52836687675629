<i18n locale="th" lang="yaml" src="@i18n/ticket/ticket.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title : "Ticket : {name}"

ticket.field.drone.serial_no : "S/N"

ticket_view.field.drone_lot : "รุ่น / Lot"
ticket_view.update.label : "แก้ไข Ticket"
ticket_view.system_message.hide : "ซ่อนข้อความจากระบบ"

ticket_view.close.normal : "ปิด Ticket"
ticket_view.close.to_service : "ให้นำเครื่องเข้าศูนย์"
ticket_view.close.to_hgr : "ให้นำเครื้่องเข้า HGR"

ticket_view.pending.software : "รอแก้ไข Software/Firmware"
ticket_view.pending.supplier : "รอการแก้ไขจากผู้ผลิต"
ticket_view.pending.evidence : "รอดูอาการ"

ticket_view.drone_detail.show_more : "แสดงข้อมูล Drone"
ticket_view.new_comment.success : "ทำการเพิ่ม Comment เรียบร้อย"

</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: ticket.ticket_no })"/>

		<div>
			<a-button
				v-if="canUpdateTicket"
				class="btn-page-action"
				type="default"
				icon="edit"
				@click="clickUpdateTicket">
				{{$t('ticket_view.update.label')}}
			</a-button>
		</div>

		<TicketDetail
			:ticket="ticket"
			:link-services="linkServices"/>
		<div v-if="ticket.id" class="mylayout-right-left responsive reverse ticket-container">
			<div class="mylayout-left">
				<div class="ticket-actions">
					<TicketActionMenu v-if="canUpdateTicketStatus"
							:ticket="ticket"
						@ticket="handleUpdatedTicket"
						@comment="handleNewComment($event,false)"/>
					<a-checkbox v-model="hideSystemComments" class="ticket-display-options">
						{{$t('ticket_view.system_message.hide')}}
					</a-checkbox>
				</div>
				<TicketCommentList
					:hide-system-message="hideSystemComments"
					:ticket="ticket"
					:comment-data-list="commentDataList"
					:can-add="canAddComment"
					@ticket="handleUpdatedTicket"
					@comment="handleNewComment"/>
			</div>
			<div class="mylayout-right">
				<ExpanableLink v-show="!isDesktop" v-model="showDetail" :button-type="true" class="drone-detail-btn">
					<DroneIcon class="anticon" />	{{$t('ticket_view.drone_detail.show_more')}}
				</ExpanableLink>
				<CollapseTransition>
					<TicketDroneDetail
						v-show="showDetail"
						class="sticky"
						:ticket="ticket"/>
				</CollapseTransition>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import DeviceMixin from "@mixins/DeviceMixin.vue"
import TicketCommentList from "@components/ticket/TicketCommentList.vue"
import TicketActionMenu from "@components/ticket/TicketActionMenu.vue"
import TicketDroneDetail from "@components/ticket/TicketDroneDetail.vue"
import ExpanableLink from "@components/common/ExpandableLink.vue"
import { CollapseTransition } from 'vue2-transitions'
import {Checkbox} from "ant-design-vue"
import axios from "axios"
import DroneIcon from "@components/icon/DroneIcon.vue"
import { fulfillTicketComment ,technician} from '@utils/objectUtil'
import TicketDetail from '@components/ticket/TicketDetail.vue'
import LoginInfoMixin from '@/src/mixins/LoginInfoMixin.vue'

export default {
	components : {
		"a-checkbox" : Checkbox,
		TicketCommentList ,  TicketActionMenu ,
		CollapseTransition ,ExpanableLink,
		TicketDroneDetail ,DroneIcon ,
		TicketDetail
	} ,
	mixins : [PageMixin,DeviceMixin,LoginInfoMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.ticket.ticket_no})
		}
	} ,
	data() {
		return {
			ticket : {} ,
			linkServices : [],
			commentDataList : [],
			dataBucket : [],
			hideSystemComments : false,
			showDetail : true,
		}
	} ,
	computed : {
		isValid() {
			return this.ticket.id > 0
		} ,
		cTicketTypes() {
			return (this.ticket.ticketTypes) ? this.ticket.ticketTypes.map((type) => {
					return this.$tenum('ticket.ticket_type',type,type)
			}).join(', ') : null
		} ,
		canUpdateTicket() {
			return this.isValid && this.$authorize('update','ticket',{companyId : this.ticket.reportByData.company.id})
				&& (this.ticket.status != 'cancel' && this.ticket.status != 'close') && this.currentUser.user_role !== technician
		} ,
		canUpdateTicketStatus() {
			return this.isValid && this.$authorize('update','ticket',{companyId : this.ticket.reportByData.company.id})
				&& this.ticket.status != 'cancel'
		} ,
		canAddComment() {
			// Manage status of ticket
			return this.ticket.id > 0 && (this.ticket.status != 'cancel' && this.ticket.status != 'close')
		} ,
		isGeneralTicket() {
			return !this.ticket.drone_id
		} ,
	} ,
	watch : {
		$route(newVal) {
			if (!this.ticket || newVal.params.id != this.ticket.id) {
				this.fetchData()
			}
		} ,
		isDesktop() {
			this.showDetail = this.isDesktop
		}
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		fetchData() {
			const ticketId = this.$route.params.id;
			if (!ticketId)
				return
			this.showPageLoading()
			this.clearBreadcrumbParams('ticketNo')
			axios.get("/api/tickets/"+ticketId).then((response) => {
				this.ticket = response.data.data.ticket
				this.linkServices = response.data.data.link_services
				this.dataBucket = response.data.data.bucket

				this.commentDataList = response.data.data.comments.map((comment)=> {
					return fulfillTicketComment(comment,this.dataBucket)
				})
				this.addBreadcrumbParams({ticketNo : this.ticket.ticket_no})
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		clickUpdateTicket() {
			this.$router.push({name:"ticket/update" , params : {id : this.ticket.id}})
		} ,
		handleNewComment(commentData,alert=true) {
			if ('users' in this.dataBucket) {
				this.dataBucket.users = {
					...this.dataBucket.users,
					...commentData.bucket.users
				}
			}
			if ('companies' in this.dataBucket) {
				this.dataBucket.companies = {
					...this.dataBucket.companies ,
					...commentData.bucket.companies
				}
			}
			this.commentDataList = [
				...this.commentDataList,
				fulfillTicketComment(commentData.comment,this.dataBucket)
			]
			if (alert)
				this.$message.success(this.$t('ticket_view.new_comment.success'))
		} ,
		handleUpdatedTicket(newTicket) {
			this.ticket = {...newTicket}
		}
	}
}
</script>

<style lang="less" scoped>
.ticket-container {
	margin-top : 24px;
}
.ticket-actions {
	.clearfix();
	margin-bottom : 12px;
}
.ticket-display-options {
	float : right;
	margin-top : 6px;
}
.drone-detail-btn {
	margin-bottom : 2px;
}
</style>

<template>
	<div>
		<a-list
			:loading="loading"
			item-layout="horizontal"
			class="ticket-list mycard-ant-list"
			:data-source="ticketDataList">
			<template v-if="showLoadingMore" v-slot:loadMore>
				<div class="load-more-pane">
					<a-spin v-if="loadingMore" />
					<a-button v-else type="link" @click="handleLoadMore">
						{{$t('common.load_more')}}
					</a-button>
				</div>
			</template>
			<template v-slot:renderItem="ticketData">
				<a-list-item>
					<TicketLiteCard
						:ticket-data="ticketData"
						size="small"
						link-target="_blank"
						:clickable="false"/>
				</a-list-item>
			</template>
		</a-list>
	</div>
</template>

<script>
import {List} from "ant-design-vue"
import TicketLiteCard from "@components/ticket/TicketLiteCard.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import {fulfillTicket} from "@utils/objectUtil"

export default {
	components : {
		TicketLiteCard,
		"a-list" : List, "a-list-item" : List.Item,
	} ,
	props : {
		ticket : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			loading : false ,
			ticketDataList : [] ,
			loadingMore : false,
			pagination : {
				pageSize : 5,
				current : 0,
				total : 0,
			}
		}
	} ,
	computed: {
		showLoadingMore() {
			return (this.pagination.current * this.pagination.pageSize) < this.pagination.total
		}
	} ,
	watch: {
		'ticket.id' : {
			handler() {
				this.newLoad()
			}
		}
	} ,
	mounted() {
		this.newLoad()
	} ,
	methods : {
		newLoad() {
			this.loading = true
			this.pagination.current = 0
			this.ticketDataList = []
			this.fetchData()
		} ,
		fetchData(){
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : this.pagination.current + 1,
				sort_field : 'event_datatime' ,
				sort_order : 'descend' ,
			}
			const filter = {
				'mode' : 'history',
			}
			if (!this.ticket.drone_id) {
				filter.is_general_ticket = true
			} else {
				filter.drone_id = this.ticket.drone_id
			}
			this.loadingMore = true;
			axios.get("/api/tickets/search",{params: {search,filter}}).then((response)=>{
				const page = response.data.data.pagination;
				const dataBucket = response.data.data.bucket;

				response.data.data.tickets.forEach((ticket)=> {
					const ticketData = fulfillTicket(ticket,dataBucket);
					if (ticketData.id && ticketData.id != this.ticket.id)
						this.ticketDataList.push(ticketData)
				})

				this.pagination.current++;
				this.pagination.total = page.total
			}).catch((error)=>{
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
				this.loadingMore = false
			})
		} ,
		handleLoadMore() {
			if (this.showLoadingMore)
				this.fetchData()
		}
	}
}
</script>
